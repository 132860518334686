html,
body {
    height: 100%;
    overscroll-behavior: contain;
    #root {
        color: #010101;
        height: 100%;
    }
}

#root {
    & > .h-full{
        height: 100%;
    }
}

body.page-content {
    overflow: hidden !important;
    padding-right: 17px;
}

.MuiIconButton-root {
    &:hover {
        background-color: #fff !important;
    }
}

.MuiButtonBase-root {
    text-transform: none !important;
    &:hover {
        background-color: transparent !important;
    }
    &.active {
        &:hover {
            background-color: #C8E6C9 !important;
        }
    }
}

.MuiMenu-list {
    padding: 0 !important;
}

.no-padding {
    padding: 0 !important;
}

.no-padding-side {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

// .MuiFormControl-root {
//     border-radius: 0.3125rem;
// }

.MuiCheckbox-root {

    &:hover {
        background-color: transparent !important;
    }
}

/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }