.size-mobile-table {
    -webkit-border-radius: 6px;
    border-radius: 6px;
    color: #010101;
    font-family: 'Nunito', sans-serif;
    font-size: 12px;
    margin: auto;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    width: 100%;
    // max-width: 280px;
    z-index: 1;
}
  
.size-mobile-table.th-shadow table tbody tr th:first-child:after,
.size-mobile-table.th-shadow table thead tr th:first-child:after {
    bottom: -1px;
    content: "";
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
    width: 30px;
    
    @media (max-width: 600px) {
        -webkit-box-shadow: inset 10px 0 8px -8px rgb(207, 209, 212);
        box-shadow: inset 10px 0 8px -8px rgb(207, 209, 212);
    }
}
  
.size-mobile-table table {
    border-collapse: separate;
    border-spacing: 0;
    margin: 0;
    white-space: nowrap;
    width: 100%;
}
  
.size-mobile-table table thead {
    background-color: #ECF0F1;
    border-bottom: 1px solid #f5f5f5;
    font-weight: 600;
    font-size: 14px;
    vertical-align: middle;
}
  
.size-mobile-table table tr {
    -webkit-align-items: center;
    align-items: center;
    height: 40px;
}
  
.size-mobile-table table thead > tr > th:first-child {
    background-color: #ECF0F1;
    height: 40px;
    left: 0;
    max-width: 123px;
    min-width: 28px;
    padding: 0 1rem;
    position: -webkit-sticky;
    position: sticky;
}
  
.size-mobile-table table thead > tr > th {
    font-weight: 600;
    max-width: 120px;
    top: 0;
    white-space: break-spaces;
    z-index: 1;
}

.size-mobile-table table tbody {
    background-color: #fff;
    border-bottom: 1px solid #f5f5f5;
    vertical-align: middle;
}
  
.size-mobile-table table tbody > tr th {
    background-color: #fff;
    font-weight: 600;
    height: 40px;
    left: 0;
    min-width: 28px;
    padding: 0 1rem;
    position: -webkit-sticky;
    position: sticky;
}
  
.size-mobile-table table tbody > tr td {
    height: 40px;
}
  
.size-mobile-table table tbody tr:nth-child(2n),
  .size-mobile-table table tbody tr:nth-child(2n) th {
    background-color: #ECF0F1;
}
  
.size-mobile-table table tr td,
  .size-mobile-table table tr th {
    min-width: 28px;
    padding: 0 1rem;
    text-align: left;
    vertical-align: middle;
}
  